import { Button, Drawer, IconButton } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import styles from "./NotificationDrawer.module.css";
import { ReactComponent as BrokenShield } from "../../../assets/StreakShield/BrokenShield.svg";
import { ReactComponent as CoinsIcon } from "../../../assets/coins.svg";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";

function formatSingleDateComparison(dateString) {
  const now = new Date();
  const inputDate = new Date(dateString);

  const diffTime = now - inputDate;
  const diffSeconds = Math.floor(diffTime / 1000);
  const diffMinutes = Math.floor(diffSeconds / 60);
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays === 0) {
    if (diffHours === 0) {
      return diffMinutes === 1 ? "1m" : `${diffMinutes}m`; // Minutes ago
    } else {
      return diffHours === 1 ? "1h" : `${diffHours}h`; // Hours ago
    }
  } else if (diffDays === 1) {
    return "1d";
  } else if (diffDays >= 2 && diffDays <= 6) {
    return `${diffDays}d`;
  } else if (diffDays >= 7 && diffDays < 14) {
    return "1w";
  } else if (diffDays >= 14) {
    return "2w";
  }
  return "";
}

const NotificationDrawer = ({ notificationDrawer, setNotificationDrawer }) => {
  const userId = localStorage.getItem("userId");
  const [notificationData, setNotificationData] = useState([]);

  const level = localStorage.getItem("level");
  const levelColors = {
    2: "#7FFF00", // Level 2 color = GREEN
    3: "#FFFF00", // Level 3 color = YELLOW
    4: "#CC0000", // Level 4 color = RED
    5: "#FF9900", // Level 5 color = #FF9900
  };
  const changeColor = levelColors[level] || "#44ccff";

  const getNotifications = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/notification/notifications/${userId}`
      );
      if (response.status === 200) {
        setNotificationData(response.data.data);
      }
    } catch (error) {
      console.error(error.response.message);
      console.error(error);
    }
  };

  useEffect(() => {
    if (notificationDrawer) {
      getNotifications();
    }
  }, [notificationDrawer]);

  return (
    <Drawer
      sx={{
        zIndex: 10000000,
        ".MuiDrawer-paper": {
          backgroundColor: "#000000",
          backgroundImage: "none",
          borderLeft: "1px solid #333333",
        },
      }}
      className={styles.notificationDrawer}
      open={notificationDrawer}
      anchor="right"
      onClose={() => {
        setNotificationDrawer(false);
      }}
    >
      <IconButton
        onClick={() => {
          setNotificationDrawer(false);
        }}
        className={styles.topButton}
      >
        <ChevronRightIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          setNotificationDrawer(false);
        }}
        className={styles.closeButton}
      >
        <CloseIcon />
      </IconButton>
      <div className={styles.mainNotificationDrawer}>
        <h2>Notification</h2>
        <div style={{ listStyle: "none" }} className={styles.list}>
          {notificationData.map((val, index) => (
            <div key={index}>
              <span className={styles.firstSpan}>
                <span>
                  {val?.notification_type === "OFFER_NO_PURCHASES" ? (
                    <CoinsIcon className={styles.svgComponent} />
                  ) : (
                    <BrokenShield
                      style={{ fill: changeColor }}
                      className={styles.svgComponent}
                    />
                  )}
                </span>
                {val?.notification_description}
              </span>
              <span style={{ color: "#A3A2A2" }}>
                {formatSingleDateComparison(val?.created_at)}
              </span>
            </div>
          ))}
        </div>
      </div>
    </Drawer>
  );
};

export default NotificationDrawer;
