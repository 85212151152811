import * as React from "react";
import { useNavigate } from 'react-router-dom';
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';
import styles from './LearnMobileDynamic.module.css';
import 'swiper/css';
import {
    Box,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Tab,
    Tabs,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { lazy, Suspense, useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { Mousewheel, FreeMode } from 'swiper/modules';
import Wrapper from '../../../components/Wrapper/Wrapper';
import UnauthNav from '../../../components/Navbar/UnauthNav';
import FooterMobile from '../../../components/Footer/FooterMobile';
import isDesktop from '../../../isDesktop';

const DesktopComponent = lazy(() => import("./LearnPageDynamic"));

const titleToURI = (title) => title.replaceAll(" ", "-").toLowerCase();

const LearnDynamicMobile = () => {
    const navigate = useNavigate();
    const [isValidToken, setIsValidToken] = useState(false);
    const [learnItems, setLearnItems] = useState([]);
    const isDesktopDevice = isDesktop();
    const token = localStorage.getItem("token");
    const [value, setValue] = React.useState(0);

    useEffect(() => {
        checkTokenValidity();
        fetchLearnItems();
    }, [token]);

    const checkTokenValidity = () => {
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                setIsValidToken(true);
            } catch (error) {
                console.error("Invalid token:", error.message);
            }
        }
    };

    const fetchLearnItems = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/learn-seo/learn/all`);
            setLearnItems(response.data.data || []);
            console.log(response.data);

        } catch (error) {
            console.error("Error fetching learn items:", error);
        }
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const renderCards = (section) => {
        return learnItems
            .filter((item) => section === "all" || item.category === section)
            .map((item) => (
                <Card
                    key={item.learn_id}
                    onClick={() => navigate(`/learn-dynamic/${titleToURI(item.title)}`)}
                    sx={{
                        width: "90.291vw",
                        height: "auto",
                        backgroundColor: "#2E2D2D25",
                        border: "2px solid #333333",
                        mb: 4, // Margin bottom for spacing
                    }}
                >
                    <CardMedia
                        sx={{ height: "10.25rem", borderBottom: "2px solid #333333" }}
                        image={item.image_url}
                        title={item.title}
                    />
                    <div className={styles.cardBottom}>
                        <CardContent>
                            <div className={styles.cardContent}>{item.title}</div>
                            <div style={{ color: "white", margin: "0.6rem 0 0 0" }} dangerouslySetInnerHTML={{ __html: item.short_description }}></div>
                        </CardContent>
                        <CardActions style={{ padding: "0 0 0.5rem 1rem" }}>
                            <a
                                style={{
                                    color: "#44CCFF",
                                    cursor: "pointer",
                                    fontSize: "0.625rem",
                                }}
                                className={styles.readMore}
                                onClick={() => handleLinkClick(item.title)}
                            >
                                Read More &#8594;
                                <hr className={styles.readMeLine} />
                            </a>
                        </CardActions>
                    </div>
                </Card>
            ));
    };

    const handleLinkClick = (title) => {
        console.log(encodeURIComponent(title), " got handleLinkClick");
        title = title.replaceAll(" ", "-").toLowerCase(); // replace spaces in title with underscores before encoding
        navigate(`/learn/${encodeURIComponent(title)}`);
    };

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Helmet>
                <title>
                    Learn - Discover Comprehensive Math Courses and Specializations
                </title>
                <meta
                    name="description"
                    content="Tailored programs ensure you're always ready to excel, whether in school or competitive exams. Unlock your full potential with SMARTSA's customized courses and specializations for every exam and aptitude test from K6 - K12 to competitive exam aspirants."
                />
            </Helmet>
            {isDesktopDevice ? (
                <DesktopComponent />
            ) : (
                <>
                    {isValidToken ? <Wrapper /> : <UnauthNav />}
                    <div
                        style={{
                            fontSize: "1.125rem",
                            margin: isValidToken
                                ? "3.5rem 0 1rem 1rem"
                                : "1.5rem 0 1rem 1rem",
                        }}
                    >
                        Learn
                    </div>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: "#44ccff",
                            },
                        }}
                        sx={{
                            width: "100%",
                            borderBottom: "1px solid #333333",
                            justifyContent: "space-between",
                            "& .MuiTabs-flexContainer": {
                                justifyContent: "space-around",
                            },
                        }}
                    >
                        <Tab label="All" className={styles.tabStyle} />
                        <Tab label="Specialization" className={styles.tabStyle} />
                        <Tab label="Skills" className={styles.tabStyle} />
                    </Tabs>
                    <div className={styles.pageWrapper}>
                        {value === 0 && (
                            <Box sx={{ p: 2 }}>
                                <div className={styles.subHeadings}>All</div>
                                {renderCards("all")}
                            </Box>
                        )}
                        {value === 1 && (
                            <Box sx={{ p: 2 }}>
                                <div className={styles.subHeadings}>Specialization</div>
                                {renderCards("specialization")}
                            </Box>
                        )}
                        {value === 2 && (
                            <Box sx={{ p: 2 }}>
                                <div className={styles.subHeadings}>Skills</div>
                                {renderCards("skill")}
                            </Box>
                        )}
                    </div>
                    {!isValidToken && <FooterMobile />}
                </>
            )}
        </Suspense>
    );
};

export default LearnDynamicMobile;