import React from 'react';
import styles from './FooterMobile.module.css';
import { ReactComponent as InstagramIcon } from '../../assets/icons/FooterIcons/InstagramIcon.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/icons/FooterIcons/LinkedInIcon.svg';
import { ReactComponent as YoutubeIcon } from '../../assets/icons/FooterIcons/YoutubeIcon.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icons/FooterIcons/FacebookIcon.svg';
import { ReactComponent as BackToTopArrowIcon } from '../../assets/icons/FooterIcons/BackToTopArrowIcon.svg';
import NewSmartsaLogo from "../../assets/smartsa-icon-mobile.svg";
import CallIcon from "../../assets/icons/other_icons/call-icon.svg";
import MailIcon from "../../assets/icons/other_icons/email-icon.svg";
import SmartsaFooterMobileIcon from "../../assets/icons/FooterIcons/SmartsaFooterMobileIcon.svg"

const FooterMobile = () => {
  return (
    <div className={styles.footerContainer}>
      <hr style={{ border: 'none', height: '1px', color: '#333', backgroundColor: '#333' }} />
      <div className={styles.footerColumn} style={{marginLeft: '1.5rem'}}>
        <img src={SmartsaFooterMobileIcon} alt="Logo" style={{width: '130px', height: '27px'}} />
        <div style={{fontSize: '1.5rem'}}>Math is not a Subject,</div>
        <div style={{fontSize: '1.5rem'}}>Math is a <span style={{ color: '#44CCFF' }}>Skill!</span></div>
      </div>

      <div className={styles.footerColumn} style={{marginLeft: '1.5rem', gap: '0.4rem !important'}}>
        <p>Reach out to us</p>
        <div style={{fontSize: '0.75rem', marginTop: '-0.75rem'}}>Get all your learning questions answered with SMARTSA!</div>
        <div>
          <img src={CallIcon} alt="Call Icon" className={styles.footerIcons} />
          <a href='tel:+917620444739' type='phone'>&#43;91 7620444739</a>
        </div>
        <div>
          <img src={MailIcon} alt="Mail Icon" className={styles.footerIcons} />
          <a href="mailto:support@smartsa.in" type='email'>support@smartsa.in</a>
        </div>
      </div>

      <div className={styles.footerHead}>
        <div className={styles.footerColumn}>
          <p>Company</p>
          <a href="/">Home</a>
          <a href="/#aboutus">About Us</a>
          <a href="/OurStory">Our Story</a>
          <a href="/how-it-works">How It Works</a>
        </div>
        <div className={styles.footerColumn}>
          <p>Resources</p>
          <a href="/learn">Learn</a>
          <a href="/lab-page">Labs</a>
          <a href="/blogs">Blogs</a>
        </div>
      </div>
      <div className={styles.socialsContainer}>
        <div className={styles.followUs}>Follow Us</div>
        <div className={styles.subFooterRight}>
          <a href="https://www.instagram.com/smartsa.in?igsh=Y3gxbzY0c2QyZ3d2" target='_blank'>
            <InstagramIcon />
          </a>
          <a href="https://www.youtube.com/@smartsa9603" target='_blank'>
            <YoutubeIcon />
          </a>
          <a href="https://www.linkedin.com/company/smartsa-india/" target='_blank'>
            <LinkedInIcon />
          </a>
          <a href="https://m.facebook.com/profile.php/?id=100086846304635&name=xhp_nt__fb__action__open_user" target='_blank'>
            <FacebookIcon />
          </a>
        </div>
      </div>
      <hr style={{ border: 'none', height: '1px', color: '#333', backgroundColor: '#333', position: 'relative' }} />
      <div className={styles.subFooter}>
        <div>&#169; 2024 SMARTSA Tech Pvt. Ltd. All right reserved</div> 
        <a href="/terms" style={{color: '#7c7c7c', textDecoration: 'none'}} target='_blank' rel='noopener noreferrer'>Terms & Conditions &#124;</a>  
        <a href="/privacy" style={{color: '#7c7c7c', textDecoration: 'none'}} target='_blank' rel='noopener noreferrer'>Privacy Policy</a>
      </div>
    </div>
  )
}

export default FooterMobile