import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID
}

TagManager.initialize(tagManagerArgs)
 


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <HelmetProvider>
    <App />
    </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();