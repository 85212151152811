import React from "react";
import DesktopNewSmartsaLogo from "../../assets/smartsa_new_logo.svg";
import styles from "./UAuthNavDesktop.module.css";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Badge } from "@mui/material";
import { color } from "framer-motion";

const UnAuthNavDesktop = () => {
  const location = useLocation();
  const isAboutUsActive = location.hash === "#aboutus";
  const navClass = ({ isActive, isPending, isTransitioning }) =>
    [
      isPending ? styles.navPending : "",
      isActive ? styles.navActive : "",
      isTransitioning ? styles.navTransitioning : "",
    ].join(" ");

  return (
    <div>
      <div style={{ background: "#000000" }} className={styles.topBar}>
        <div className={styles.logoIcon}>
          <Link to="/">
            <img
              src={DesktopNewSmartsaLogo}
              className={styles.iconStyle}
              alt="Smartsa Logo"
            />
          </Link>
        </div>
        <div className={styles.topBarRight}>
          <ul className={styles.linkList}>
            <li>
              <NavLink className={isAboutUsActive && styles.navActive} to={"/#aboutus"}>
                About us
              </NavLink>
            </li>
            <li>
              <NavLink className={navClass} to={"/OurStory"}>
                Our Story
              </NavLink>
            </li>
            <li>
              <NavLink className={navClass} to={"/learn"}>
                Learn
              </NavLink>
            </li>
            <li>
              <NavLink className={navClass} to={"/lab-page"}>
                Labs
              </NavLink>
            </li>
            <li>
              <NavLink to={"/blogs"} className={navClass}>
                Blogs
              </NavLink>
            </li>
            <li>
              {/* <a href="/how-it-works">How it Works</a> */}
              <NavLink to={"/how-it-works"} className={navClass}>
                How it Works
              </NavLink>
            </li>
            <li>
              <NavLink className={navClass} to={"/help"}>
                Help
              </NavLink>
            </li>
            <li>
              <NavLink to={"/signup"}>
                <button className={styles.SignupButton} >Signup</button>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UnAuthNavDesktop;
