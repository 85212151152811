// utils/indexedDB.js

// Initialize the IndexedDB database
export function initIndexedDB(dbName, storeName, version) {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName, version);
  
      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains(storeName)) {
          db.createObjectStore(storeName, { keyPath: 'key' });
        }
      };
  
      request.onsuccess = (event) => {
        resolve(event.target.result);
      };
  
      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  }
  
  // Save a boolean value in IndexedDB
  export function setBoolean(db, storeName, key, value) {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([storeName], 'readwrite');
      const store = transaction.objectStore(storeName);
  
      const request = store.put({ key, value });
  
      request.onsuccess = () => {
        resolve();
      };
  
      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  }
  
  // Retrieve a boolean value from IndexedDB
  export function getBoolean(db, storeName, key) {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction([storeName]);
      const store = transaction.objectStore(storeName);
  
      const request = store.get(key);
  
      request.onsuccess = (event) => {
        resolve(event.target.result ? event.target.result.value : null);
      };
  
      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  }

  export function deleteIndexedDB(dbName) {
    console.log(dbName);
    
    var request = indexedDB.deleteDatabase(dbName);
    console.log(request);
    
    request.onsuccess = function(event) {
      console.log(`Database '${dbName}' deleted successfully.`);
    };
  
    request.onerror = function(event) {
      console.error(`Error deleting database '${dbName}':`, event.target.errorCode);
    };
  
    request.onblocked = function(event) {
      console.warn(`Database deletion request for '${dbName}' is blocked.`);
    };
  }
  