import { useState,useEffect } from "react";
import Loader from "../Loader/Loader";

const ManualLoader = ({ children }) => {
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
      const timer = setTimeout(() => {
        setLoaded(true);
      }, 1000);
  
      return () => clearTimeout(timer);
    });
  
    return loaded ?  children  : <Loader />;
  };

  export default ManualLoader