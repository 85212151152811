import React, { useState } from "react";
import styles from "./UnauthNav.module.css";
import { Link, NavLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { ClickAwayListener, IconButton } from "@mui/material";
import NewSmartsaLogo from "../../assets/smartsa_new_logo.svg";
import { ReactComponent as LabSeoIcon } from "../../assets/labs_seo.svg"
import { ReactComponent as LearnSeoIcon } from "../../assets/learn_seo.svg"
import { ReactComponent as OurStoryIcon } from "../../assets/ourstory_seo.svg"
import  { ReactComponent as BlogsIcon } from "../../assets/blogs_seo.svg"
import { ReactComponent as HelpIcon } from "../../assets/icons/other_icons/help-icon.svg";
import { ReactComponent as HowItWorksIcon } from "../../assets/how-it-works-icon.svg"
import { ReactComponent as AboutUsIcon } from "../../assets/about-us-icon.svg"

const UnauthNav = () => {
  const [open, setOpen] = useState(false);
  const onMenuClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  console.log(open);

  return (
    <>
      <div className={styles.mainHeader}>
        <div className={styles.leftContainer}>
          <div className={styles.menuButtonContainer}>
            <IconButton
              style={{
                backgroundColor: "#000",
                color: "#ffffff",
                padding: "0",
              }}
              onClick={onMenuClick}
              size="small"
              color="default"
            >
              <MenuIcon fontSize="small" />
            </IconButton>
          </div>

          <Link to={"/"}>
            <img src={NewSmartsaLogo} alt="Logo" />
          </Link>
        </div>
        <div className={styles.rightContainer}>
          <NavLink to={"/signup"} style={{textDecoration: 'none'}}>Signup</NavLink>
          <NavLink to={"/help"} style={{textDecoration: 'none'}}>Help?</NavLink>
        </div>
      </div>
      {open && (
        <ClickAwayListener onClickAway={onClose}>
          <div
            className={`${styles.MenuContainer} ${open ? styles.isOpen : ""}`}
          >
            <div className={styles.menuLogoContaier}>
              <Link to={"/"}>
                <img src={NewSmartsaLogo} alt="MenuLogo" />
              </Link>
            </div>
            <ul>
              <li key={1} style={{ borderBottom: window.location.pathname.startsWith("/learn") ? '1px solid #44ccff' : "1px solid #333"}}>
                <LearnSeoIcon fill="#cccccc"/>
                <NavLink to={"/learn"} >Learn</NavLink>
              </li>
              <li key={2} style={{ borderBottom: window.location.pathname.startsWith("/lab-page") ? '1px solid #44ccff' : "1px solid #333"}}>
                <LabSeoIcon fill="#cccccc"/>
                <NavLink to={"/lab-page"} >Labs</NavLink>{" "}
              </li>
              <li key={3} style={{ borderBottom: window.location.pathname.startsWith("/aboutus") ? '1px solid #44ccff' : "1px solid #333"}}>
              <AboutUsIcon fill="#cccccc"/>
                <NavLink to={"/aboutus"} className={({ isActive }) => (isActive ? styles.activeLink : undefined)}>About Us</NavLink>
              </li>
              <li key={4} style={{ borderBottom: window.location.pathname.startsWith("/OurStory") ? '1px solid #44ccff' : "1px solid #333"}}>
                <OurStoryIcon fill="#cccccc"/>
                <NavLink to={"/OurStory"} className={({ isActive }) => (isActive ? styles.activeLink : undefined)}>Our Story</NavLink>
              </li>
              <li key={5} style={{ borderBottom: window.location.pathname.startsWith("/how-it-works") ? '1px solid #44ccff' : "1px solid #333"}}>
                <HowItWorksIcon fill="#cccccc"/>
                <NavLink to={"/how-it-works"} className={({ isActive }) => (isActive ? styles.activeLink : undefined)}>How it Works</NavLink>
              </li>
              <li key={6} style={{ borderBottom: window.location.pathname.startsWith("/help") ? '1px solid #44ccff' : "1px solid #333"}}>
                <HelpIcon fill="#cccccc"/>
                <NavLink to={"/help"} className={({ isActive }) => (isActive ? styles.activeLink : undefined)}>Contact Us</NavLink>
              </li>
              <li key={7} style={{ borderBottom: window.location.pathname.startsWith("/blogs") ? '1px solid #44ccff' : "1px solid #333"}}>
                <BlogsIcon fill="#cccccc"/>
                <NavLink to={"/blogs"} className={({ isActive }) => (isActive ? styles.activeLink : undefined)}>Blogs</NavLink>
              </li>
            </ul>
          </div>
        </ClickAwayListener>
      )}
    </>
  );
};

export default UnauthNav;
